<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ categoryName }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item"><a [routerLink]="'/tin-tuc/'">Tin tức</a></li>
          <li class="breadcrumb-item">{{ categoryName }}</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<!--News -->
<div class="news">
  <div class="container card-block pt-3">
    <div class="title-top mb-3" style="font-size: 21px">{{ 'newsPage.list' | translate }}</div>

    <div class="row" [dsMatchHeight]="'card'">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-3" *ngFor="let row of news">
        <div class="category card">
          <div class="row">
            <div class="col-sm-6 col-md-12 col-lg-6 col-image">
              <div class="image">
                <a [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                  <img
                    width="300"
                    height="187"
                    class="image"
                    style="width: 100%; height: auto"
                    [src]="static(row.imageSmall)"
                    alt="{{ row.thumbnailSeonews }}"
                  />
                </a>
              </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6">
              <div class="content">
                <div class="title mb-2 mt-md-3 mt-3 m-sm-0 m-lg-0">
                  <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                    <h2>
                      {{ row.titleName }}
                    </h2></a
                  >
                </div>
                <div class="text" [innerHTML]="row.shortDescription | safeHtml"></div>
                <div class="more">
                  <a [title]="row.titleName" class="link" [routerLink]="['/tin-tuc', row.linkCategory, row.linkCode]">
                    {{ 'buttons.readmore' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoadding">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="timeline-wrapper">
            <div class="timeline-item">
              <div class="animated-background">
                <div class="background-masker header-top"></div>
                <div class="background-masker header-left"></div>
                <div class="background-masker header-right"></div>
                <div class="background-masker header-bottom"></div>
                <div class="background-masker subheader-left"></div>
                <div class="background-masker subheader-right"></div>
                <div class="background-masker subheader-bottom"></div>
                <div class="background-masker content-top"></div>
                <div class="background-masker content-first-start"></div>
                <div class="background-masker content-first-end"></div>
                <div class="background-masker content-second-line"></div>
                <div class="background-masker content-second-end"></div>
                <div class="background-masker content-third-line"></div>
                <div class="background-masker content-third-end"></div>
                <div class="background-masker content-fourth-line"></div>
                <div class="background-masker content-fifth-line"></div>
                <div class="background-masker content-sixth-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="timeline-wrapper">
            <div class="timeline-item">
              <div class="animated-background">
                <div class="background-masker header-top"></div>
                <div class="background-masker header-left"></div>
                <div class="background-masker header-right"></div>
                <div class="background-masker header-bottom"></div>
                <div class="background-masker subheader-left"></div>
                <div class="background-masker subheader-right"></div>
                <div class="background-masker subheader-bottom"></div>
                <div class="background-masker content-top"></div>
                <div class="background-masker content-first-start"></div>
                <div class="background-masker content-first-end"></div>
                <div class="background-masker content-second-line"></div>
                <div class="background-masker content-second-end"></div>
                <div class="background-masker content-third-line"></div>
                <div class="background-masker content-third-end"></div>
                <div class="background-masker content-fourth-line"></div>
                <div class="background-masker content-fifth-line"></div>
                <div class="background-masker content-sixth-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark mx-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="justify-content-center text-center mt-3">
      <button *ngIf="isLoadMore" [wscroll]="LoadMoreOrder" class="btn btn-pill btn-outline-primary">Xem thêm</button>
    </div>

    <!-- <app-pagination (goPage)="goToPage($event)" (changeLimit)="changeLimit()" (goNext)="onNext()" (goPrev)="onPrev()"
      [pagesToShow]="6" [page]="query.page" [perPage]="query.limit" [count]="query.total"></app-pagination> -->
  </div>
</div>
